export default function servicesSearch() {
    var ajaxSearchServices = function (ids) {
    //   var input = jQuery("#actes-search-input").val();
      jQuery.ajax({
        url: "/wp-admin/admin-ajax.php",
        type: "POST",
        data: {
          action: "fiches_metiers_search",
          search_term: ids,
        //   search_input: input,
        },
        beforeSend: function () {
          jQuery(".services-posts").empty();
          jQuery(".loader").show();
        },
        success: function (data) {
          jQuery(".loader").hide();
          jQuery(".services-posts").html(data);
        },
      });
      return false;
    };
  
    var cats = jQuery(".tri_cats .cat");
    var ids = [];
    cats.each(function () {
      var cat = jQuery(this);
      cat.on("click", function () {
        if (cat.hasClass("active")) {
          cat.removeClass("active");
          if (ids.includes(cat.data("id"))) {
            const index = ids.indexOf(cat.data("id"));
            if (index > -1) {
              ids.splice(index, 1);
            }
          }
        } else {
          jQuery('.tri_cats  .cat').removeClass('active');
          cat.addClass("active");
          ids = [];
          ids.push(cat.data("id"));
        }
        console.log(ids);
        ajaxSearchServices(ids);
      });
    });
  
    let timer = null;
    if (jQuery("body").hasClass("page-template-fiches-metiers")) {
        ajaxSearchServices(ids);
    //   jQuery("#actes-search-input").keyup(function (e) {
    //     clearTimeout(timer);
    //     timer = setTimeout(ajaxSearchActes, 500, ids);
    //   });
    }
  }
  