export default function dossiersDePresseSearch() {
    var ajaxSearchDossiersDePresse = function (ids) {
    //   var input = jQuery("#actes-search-input").val();
      jQuery.ajax({
        url: "/wp-admin/admin-ajax.php",
        type: "POST",
        data: {
          action: "dossiers_de_presse_search",
          search_term: ids,
        //   search_input: input,
        },
        beforeSend: function () {
          jQuery(".dossiers-de-presse-posts").empty();
          jQuery(".loader").show();
        },
        success: function (data) {
          jQuery(".loader").hide();
          jQuery(".dossiers-de-presse-posts").html(data);
        },
      });
      return false;
    };
  
    var cats = jQuery(".tri_cats .cat");
    var ids = [];
    cats.each(function () {
      var cat = jQuery(this);
      cat.on("click", function () {
        if (cat.hasClass("active")) {
          cat.removeClass("active");
          if (ids.includes(cat.data("id"))) {
            const index = ids.indexOf(cat.data("id"));
            if (index > -1) {
              ids.splice(index, 1);
            }
          }
        } else {
          cat.addClass("active");
          ids.push(cat.data("id"));
        }
        console.log(ids);
        ajaxSearchDossiersDePresse(ids);
      });
    });
  
    let timer = null;
    if (jQuery("body").hasClass("page-template-dossiers-de-presse")) {
      ajaxSearchDossiersDePresse(ids);
    }
  }
  