import LazyLoad from "vanilla-lazyload";

export default function actualitesAgentsSearch() {
  var ajaxSearchActus = function (ids) {
    //   var input = jQuery("#actes-search-input").val();
    jQuery.ajax({
      url: "/wp-admin/admin-ajax.php",
      type: "POST",
      data: {
        action: "actualites_agents_search",
        search_term: ids,
        //   search_input: input,
      },
      beforeSend: function () {
        jQuery(".actualites-posts").empty();
        jQuery(".loader").show();
      },
      success: function (data) {
        jQuery(".loader").hide();
        jQuery(".actualites-posts").html(data);
        var lazyLoad = new LazyLoad();
      },
    });
    return false;
  };

  var cats = jQuery(".tri_cats .cat");
  var ids = [];
  cats.each(function () {
    var cat = jQuery(this);
    cat.on("click", function () {
      if (!cat.hasClass('thematiques_cat')) {

        jQuery('.single-cat').each(function() {
            jQuery(this).not(cat).removeClass('active')
            let indexCat = ids.indexOf(jQuery(this).data("id"));
            if ( indexCat > -1) {
              ids.splice(indexCat, 1);
            }
        })
      }
      if (cat.hasClass("active")) {
        cat.removeClass("active");
        if (ids.includes(cat.data("id"))) {
          const index = ids.indexOf(cat.data("id"));
          if (index > -1) {
            ids.splice(index, 1);
          }
        }
      } else {
        cat.addClass("active");
        ids.push(cat.data("id"));
      }
      console.log(ids);
      ajaxSearchActus(ids);
    });
  });

  let timer = null;
  if (jQuery("body").hasClass("page-template-actualites-agents")) {
    ajaxSearchActus(ids);
    //   jQuery("#actes-search-input").keyup(function (e) {
    //     clearTimeout(timer);
    //     timer = setTimeout(ajaxSearchActes, 500, ids);
    //   });
  }
}
