import "../sass/main.scss";
import "jquery.marquee";
import "@fancyapps/fancybox";
import LazyLoad from "vanilla-lazyload";
import MicroModal from "micromodal";
import actesSearch from "./actes-search";
import actualitesAgentsSearch from "./actualites-agents-search";
import globalSearch from "./global-search";
import faqSearch from "./faq-search";
import mainMenu from "./modules/main-menu";
import { Tabs } from "./utils/tabs";
import { eventFire } from "./modules/helpers";
import { partenairesSlider } from "./modules/sliders";
import organigrammeExtranet from "./organigramme";
import servicesSearch from "./services-search";
import comptesRendusSearch from "./comptes-rendus-search";
import supportsDeComSearch from "./supports-de-com-search";
import campagneDeComSearch from "./campagne-de-com-search";
import dossiersDePresseSearch from "./dossiers-de-presse-search";

jQuery(function () {
  console.log("main.js");

  jQuery("img[usemap]").rwdImageMaps();
  MicroModal.init();

  var sliderInt = document.querySelector(".sliderInt");
  if (sliderInt !== null) partenairesSlider();

  jQuery.expr[":"].external = function (obj) {
    return obj.hostname != location.hostname && obj.href.startsWith("http");
  };

  jQuery(".main-int__right a").on("click", function (e) {
    e.preventDefault();
    window.open(jQuery(this)[0].href, "_blank");
  });

  jQuery("#access-link").on("click", function () {
    eventFire(document.getElementById("uci_link"), "click");
  });

  jQuery(".partager__share").on("click", function () {
    jQuery(".partager__hover").toggleClass("open");
  });

  jQuery(".main-int__content a:external").attr("target", "_blank");
  jQuery(".main-int__content a:external").append(
    ' <i class="fal fa-external-link-alt"></i>'
  );
  var lazyLoad = new LazyLoad();
  globalSearch();
  faqSearch();
  mainMenu();

  if (jQuery(".page-template-actualites-agents").length != 0) {
    actualitesAgentsSearch();
  } else if (jQuery(".page-template-fiches-metiers").length != 0) {
    servicesSearch();
  } else if (jQuery(".page-template-comptes-rendus").length != 0) {
    comptesRendusSearch();
  } else if (jQuery(".page-template-supports-de-communication").length != 0) {
    supportsDeComSearch();
  } else if (jQuery(".page-template-campagnes-de-communication").length != 0) {
    campagneDeComSearch();
  } else if (jQuery(".page-template-dossiers-de-presse").length != 0) {
    dossiersDePresseSearch();
  } else {
    actesSearch();
  }
  var url = location;
  if (
    url.pathname == "/linformation-du-sch/documentation/" ||
    url.pathname == "/documentation/"
  ) {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    var cat = urlParams.get('cat');

    let catButton = jQuery(`#cat-${cat}`);

    if (catButton) {

      let position = catButton.data('pos') +1

      var tabs_container = document.getElementById("tabs");
      if (tabs_container !== null) {
        const tabs = new Tabs({
          elem: "tabs",
          open: position,
        });
      } else {
        var tabs_container = document.getElementById("tabs");
        if (tabs_container !== null) {
          const tabs = new Tabs({
            elem: "tabs",
            open: 0,
          });
        }
      }
    }
  } else {
    var tabs_container = document.getElementById("tabs");
    if (tabs_container !== null) {
      const tabs = new Tabs({
        elem: "tabs",
        open: 0,
      });
    }
  }

  jQuery(".flash-infos__marquee").marquee({
    duration: 30000,
    gap: 0,
    delayBeforeStart: 0,
    direction: "left",
    duplicated: false,
    pauseOnHover: true,
    startVisible: true,
  });

  jQuery('[data-fancybox="galerie"]').fancybox({
    thumbs: {
      autoStart: true,
    },
  });

  jQuery(
    ".liste-depliable__title > span > a, h2.content__h2 a, h1.content__title a"
  ).each(function () {
    jQuery(this).replaceWith(jQuery(this).html());
  });

  jQuery(document).on("click", ".content__body path", function () {
    jQuery("html,body").animate(
      {
        scrollTop: jQuery(".hotspots-placeholder").offset().top,
      },
      "slow"
    );
  });
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  function zoomOutMobile() {
    var viewport = document.querySelector('meta[name="viewport"]');

    if (viewport) {
      viewport.content = "initial-scale=0.5";
      viewport.content = "width=" + vw;
      // viewport.content = "width=device-width";
    }
  }

  jQuery(".image-interactive area").on("click", function () {
    zoomOutMobile();
  });

  if (jQuery(".container-organigramme").length != 0) {
    organigrammeExtranet();
  }

  if (jQuery(".addFavButton").length != 0) {
    jQuery(".addFavButton").on("click", function () {
      var id = jQuery(this).data("idcontact");
      var isFav = jQuery(this).data("isfav");

      ajaxContactFav(id, isFav);
    });

    var ajaxContactFav = function (id, isFav) {
      jQuery.ajax({
        url: "/wp-admin/admin-ajax.php",
        type: "POST",
        data: {
          action: "add_contacts_to_home",
          id: id,
          is_fav: isFav,
        },
        success: function (data) {
          console.log(data);
          if (data.fav == true) {
            jQuery(`[data-idcontact='${data.id}']`).html(
              '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>'
            );
          } else {
            jQuery(`[data-idcontact='${data.id}']`).html(
              '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M287.9 435.9L150.1 509.1C142.9 513.4 133.1 512.7 125.6 507.4C118.2 502.1 114.5 492.9 115.1 483.9L142.2 328.4L31.11 218.2C24.65 211.9 22.36 202.4 25.2 193.7C28.03 185.1 35.5 178.8 44.49 177.5L197.7 154.8L266.3 13.52C270.4 5.249 278.7 0 287.9 0C297.1 0 305.5 5.25 309.5 13.52L378.1 154.8L531.4 177.5C540.4 178.8 547.8 185.1 550.7 193.7C553.5 202.4 551.2 211.9 544.8 218.2L433.6 328.4L459.9 483.9C461.4 492.9 457.7 502.1 450.2 507.4C442.8 512.7 432.1 513.4 424.9 509.1L287.9 435.9zM226.5 168.8C221.9 178.3 212.9 184.9 202.4 186.5L64.99 206.8L164.8 305.6C172.1 312.9 175.5 323.4 173.8 333.7L150.2 473.2L272.8 407.7C282.3 402.6 293.6 402.6 303 407.7L425.6 473.2L402.1 333.7C400.3 323.4 403.7 312.9 411.1 305.6L510.9 206.8L373.4 186.5C362.1 184.9 353.1 178.3 349.3 168.8L287.9 42.32L226.5 168.8z"/></svg>'
            );
          }
        },
      });
    };
  }

  if (jQuery(".trombinoscope__item-agents").length != 0) {
    console.log("exist");
    jQuery(".trombinoscope__image").mouseenter(function () {
      console.log("hover");
      jQuery(this).siblings(".description-agents").addClass("des-active");
    });
    jQuery(".description-agents").mouseover(function () {
      jQuery(this).addClass("des-active");
    });
    jQuery(".description-agents").mouseleave(function () {
      jQuery(this).removeClass("des-active");
    });
    jQuery(".trombinoscope__image").mouseleave(function () {
      jQuery(this).siblings(".description-agents").removeClass("des-active");
    });
  }
});
