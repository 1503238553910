export default function organigrammeExtranet() {
  // organigramme
  var services = jQuery(".service");
  var poles = jQuery(".pole");
  var sous_poles = jQuery(".sous-pole");

  services.each(function () {
    jQuery(this)
      .children(".service-info")
      .on("click", function () {
        jQuery(
          ".service-poles, .service-postes, .pole-sous_poles, .pole-postes, .sous-pole__postes"
        )
          .not(jQuery(this).siblings(".service-poles, .service-postes"))
          .removeClass("active");

        if (jQuery(this).siblings(".service-postes").length != 0) {
          jQuery(this).siblings(".service-postes").toggleClass("active");
        }
        if (jQuery(this).siblings(".service-poles").length != 0) {
          jQuery(this).siblings(".service-poles").toggleClass("active");
        }
      });
  });

  poles.each(function () {
    jQuery(this)
      .children(".pole-info")
      .on("click", function () {
        jQuery(".pole-sous_poles, .pole-postes, .sous-pole__postes")
          .not(jQuery(this).siblings(".pole-sous_poles, .pole-postes"))
          .removeClass("active");

        if (jQuery(this).siblings(".pole-postes").length != 0) {
          jQuery(this).siblings(".pole-postes").toggleClass("active");
        }

        if (jQuery(this).siblings(".pole-sous_poles").length != 0) {
          jQuery(this).siblings(".pole-sous_poles").toggleClass("active");
        }
      });
  });

  sous_poles.each(function () {
    jQuery(this)
      .children(".sous-pole__info")
      .on("click", function () {
        jQuery(".sous-pole__postes")
          .not(jQuery(this).siblings(".sous-pole__postes"))
          .removeClass("active");

        if (jQuery(this).siblings(".sous-pole__postes").length != 0) {
          jQuery(this).siblings(".sous-pole__postes").toggleClass("active");

          let width_postes = parseFloat(
            jQuery(this).siblings(".sous-pole__postes").width()
          );
          let distance_to_left = parseFloat(jQuery(this).offset().left);
          var window_width = parseFloat(jQuery(window).width());
          let distance = parseFloat(
            window_width - (distance_to_left + width_postes + width_postes)
          );

          if (window_width > parseFloat(500)) {
            if (distance < parseFloat(30)) {
              jQuery(this).siblings(".sous-pole__postes").css("right", "110%");
            } else {
              jQuery(this).siblings(".sous-pole__postes").css("right", "-110%");
            }
          }
        }
      });
  });

  // pop up organigramme
  var membres = jQuery(".agent-id");

  var closePopUp = function () {
    var popUpActive = jQuery(".pop-active .popmembre-close");
    popUpActive.on("click", function () {
      jQuery(".pop-active").removeClass("pop-active");
    });
  };

  var ajaxPopMembre = function (id) {
    jQuery.ajax({
      url: "/wp-admin/admin-ajax.php",
      type: "POST",
      data: {
        action: "pop_membres",
        search_term: id,
      },
      success: function (data) {
        jQuery(".popmembre").html(data);
        jQuery(".pop-agent-" + id).toggleClass("pop-active");
        closePopUp();
      },
    });
  };

  membres.each(function () {
    jQuery(this).on("click", function () {
      var window_width = jQuery(window).width();

      if (window_width < 600) {
        window.location.href =
          window.location.origin +
          "/les-agents-et-elus-du-sch/les-fiches-metiers/";
      }

      let id = jQuery(this)[0].dataset.id;
      let width_pop = jQuery(".pop-agent-" + id).width();
      let distance_to_left = jQuery(".container-agent-" + id).offset().left;
      let distance = window_width - (distance_to_left + width_pop);

      jQuery(".container-pop-membre")
        .not(jQuery(".pop-agent-" + id))
        .removeClass("pop-active");

      if (window_width > 900) {
        if (distance < 600) {
          jQuery(".container-pop-membre").css("right", "160px");
        } else {
          jQuery(".container-pop-membre").css("right", "-270px");
        }
      } else {
        if (distance < 200) {
          jQuery(".container-pop-membre").css("right", "160px");
        } else {
          jQuery(".container-pop-membre").css("right", "-270px");
        }
      }

      ajaxPopMembre(id);
    });
  });

  jQuery(".orga-button").on("click", function () {
    // DISPLAYING ORGA
    jQuery(".organigramme-complete").css("display", "flex");
    jQuery(".organigramme-desktop").css("display", "none");

    // DISPLAYING BUTTON
    jQuery(".orga-button-desktop").css("display", "block");
    jQuery(".orga-button").css("display", "none");

    services.each(function () {
      var childrens = jQuery(this).children();

      if (!childrens[0].classList.contains("active")) {
        childrens[0].classList.add("active");
      }

      if (!childrens[1].classList.contains("active")) {
        childrens[1].classList.add("active");
      }
    });
  });

  jQuery(".orga-button-desktop").on("click", function () {
    // DISPLAYING ORGA
    jQuery(".organigramme-complete").css("display", "none");
    jQuery(".organigramme-desktop").css("display", "flex");

    // DISPLAYING BUTTON
    jQuery(".orga-button-desktop").css("display", "none");
    jQuery(".orga-button").css("display", "flex");

    services.each(function () {
      var childrens = jQuery(this).children();

      if (childrens[0].classList.contains("active")) {
        childrens[0].classList.remove("active");
      }

      if (childrens[1].classList.contains("active")) {
        childrens[1].classList.remove("active");
      }
    });
  });
}
