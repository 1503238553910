export default function faqSearch() {
  var ajaxSearch = function () {
    var searchTerm = jQuery("#faq-input").val();
    jQuery.ajax({
      url: "/wp-admin/admin-ajax.php",
      type: "POST",
      data: {
        action: "faq_search",
        search_term: searchTerm,
      },
      beforeSend: function () {
        jQuery("#faq-results").empty();
        jQuery(".loader").show();
      },
      success: function (data) {
        jQuery(".loader").hide();
        jQuery("#faq-results").html(data);
        jQuery(".liste-depliable__title").on("click", function () {
          jQuery(this).toggleClass("active");
          jQuery(this).next(".liste-depliable__content").slideToggle();
        });
      },
    });
    return false;
  };

  let timer = null;
  jQuery("#faq-input").keyup(function (e) {
    clearTimeout(timer);
    timer = setTimeout(ajaxSearch, 500);
  });

  jQuery("#faq").on("click", ajaxSearch);
  ajaxSearch();
}
